// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-dossier-js": () => import("/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/src/pages/dossier.js" /* webpackChunkName: "component---src-pages-dossier-js" */),
  "component---src-pages-feedback-thanks-js": () => import("/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/src/pages/feedback-thanks.js" /* webpackChunkName: "component---src-pages-feedback-thanks-js" */),
  "component---src-pages-feedback-js": () => import("/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/w4aadeyemo/Projects/lets-go-tours-booked-offers/.cache/data.json")

